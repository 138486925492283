import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import ImageHero from "../images/hero-404-error-copy.svg"

const NotFoundPage = () => (
  <Layout pageid="404">
    <Seo title="404 Error" description="This is not the page you’re looking for." ogDesc="This is not the page you’re looking for." lang="en-US" meta=""/>
      <div id="hero-image-container" className="mb-4 md_mb-9">
        <h1 className="four-oh-four-copy">404</h1>
        <img src={ImageHero} alt="" id="hero-four-oh-four" />
      </div>

      <div className="text-center">
        <div id="four-oh-four" className="mb-5 md_mb-9">
          <div className="content-container">
            <h2 className="section-headline mb-2 md_mb-2">Don’t worry, we’ll be back on track in no time!</h2>
            <p>This page does not exist or another error has occurred.</p>
            <p className="mb-1 md_mb-1"> Visit our home page or go back to the previous page.</p>
            <Link to="/"><button>RETURN HOME</button></Link>
          </div>
        </div>
      </div>

  </Layout>
)

export default NotFoundPage
